import styles from "../styles/login.css";

const About = () => {
  return (
    <div className="comingSoon">
      <h1>ARTIST LOGIN, WORKSHOPS & MORE &lt;&lt; COMING SOON &gt;&gt;</h1>
    </div>
  );
};

export default About;
